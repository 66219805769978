:root {
    --primary: #0029A1;
}

.btn {
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 700px;
    padding: 8px 20px;
    border-radius: 12px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background:#fff;
    color: #002A91;
    transition: all 0.3s ease-out;
}