:root {
    --button-color: #D9D9D9;
    --text-color: #406CDC;
}

.washtype-container {
  background: #0029A1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  object-fit: contain;
}

.washtype-wrapper {
  max-width: 70%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}

.washtype-item {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.washtype-subtitle {
  align-self: center;
  display: inline-block;
  text-align: center;
  margin: 2rem;
  padding: 0.5rem 4rem;
  background-color: var(--button-color);
  border-radius: 12px;
  color: var(--text-color);
}

.washtype-desc {
  color: #fff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;

  /* or 167% */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0 1 rem 0;
  margin-bottom: 2rem;
}

.washtype-desc > ul > li {
  margin-left: 1em;
}


@media screen and (max-width: 960px) {
  .washtype-wrapper {
    flex-direction: column;
    gap: 0px;
  }

  .washtype-subtitle {
    margin: 1rem;
    font-size: 20px;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .washtype-item {
    max-width: 100%;
  }

  .wasthype-desc > p{
    margin-bottom: 1rem;
  }

  .washtype-desc {
    font-size: 20px;
  }

  .washtype-desc > ul > li {
    margin-left: 1em;
    font-size: 20px;
    margin: 2px;
  }
  
}

