.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-figure > figcaption{
  max-width: 35%;
  align-self: flex-start;
  padding-left: 4rem;
  z-index: 100;
}

.hero-figure > figcaption > p {
  color: #fff;
  font-size: 96px;
  font-weight: 900;
  text-align: left;
}

.hero-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.hero-figure {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  padding-bottom: 13%;
  overflow: hidden;
}


.hero-bottom-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hero-bottom-wrapper {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #0029A1;
  align-items: center;
  border-radius: 12px;

}

.hero-bottom-items {
  background-color: #0029A1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.hero-bottom-items > h1 {
  color: #fff;
  font-size: 40px;
  display: inline;
  margin: 1rem 0 1rem 0;
}

.hero-bottom-items > i {
  color: #fff;
  margin-top: -5px;
  margin-right: 1rem;
  font-size: 3em;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .hero-figure > figcaption > p {
    font-size: 72px;
    max-width: 100%;
  }

  .hero-bottom-wrapper {
    flex-direction: column;
  }

  .hero-bottom-items {
      padding: 0.5rem;
      gap: 20px;
      
  }

  .hero-bottom-items > h1 {
    font-size: 24px;
  }
}