#about {
    scroll-margin-top: 110px;
}

.about-container {
    background: #0029A1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2rem;
}

.about-container > h1 {
    margin-top: 1rem;
    color: #fff;
}

.about-wrapper {
    display: flex;
    width: 70%;
    max-width: 70%;
    flex-direction: column;
    justify-content: center;
}

.about-wrapper > p {
    text-align: left;
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    margin: 1rem 0 1rem 0;
}

.about-items {
    width: 70%;
    max-width: 70%;
    display: flex;
    align-items: row;

}

@media screen and (max-width: 960px) {
    .about-wrapper {
        width: 90%;
        max-width: 90%;
    }
    .about-container > h1 {
        font-size: 20px;
    }

    .about-wrapper > p {
        margin: 0.5rem 0 0.5rem 0;
        font-size: 20px;
    }
}