footer {
  background: #0029A1;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 222px
}

.footer-container {
    background: #0029A1;
    width: 100%;
    padding: 30px 0px;
    max-height: 200px;
    
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-evenly;
  }

.contacts {
  margin-right: 20%;
}

.socials {
  margin-left: 20%;
}

.socials h3 {
  text-align: left;
}
  
  footer h3 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  footer p {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
  }
  
  footer ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-bottom: 20px;
    gap: 30px;
    display: flex;
  }
  
  footer ul li i {
    color: #fff;
    font-size: 3em;
    margin-bottom: 10px;
    display: inline;
  }

  footer ul li i:hover {
    color: black;
  }

  @media screen and (max-width: 480px) {

    .footer-container {
      justify-content: center;
    }

    .contacts {
      margin-right: 0%;
    
    }
    
    .socials {
      margin-left: 3%;
    }

    .contacts > h3 {
      font-size: 12px;
    }
    .socials > h3 {
      font-size: 12px;
    }

    footer ul li i {
      color: #fff;
      font-size: 2em;
      margin-bottom: 10px;
      display: inline;
    }
  }

@media screen and (max-width: 960px) and (min-width: 480px) {

  .contacts {
    margin-right: 5%;
  
  }
  
  .socials {
    margin-left: 5%;
  }

  .contacts > h3 {
    font-size: 20px;
  }
  .socials > h3 {
    font-size: 20px;
  }

  footer ul li i {
    color: #fff;
    font-size: 2em;
    margin-bottom: 10px;
    display: inline;
  }
}
  