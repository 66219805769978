* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
  }
  
  
:root {
    --primary: #0029A1;
}

.btn {
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 700px;
    padding: 8px 20px;
    border-radius: 12px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: #0029A1;
    background-color: var(--primary);
    color: #fff;
    border: 1px solid #0029A1;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #0029A1;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background:#fff;
    color: #002A91;
    transition: all 0.3s ease-out;
}
.navbar {
  background: #fff;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  height: 105px;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  
}

.nav-links {
  color: #0029A1;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 900;
  font-size: 32px;
  padding: 0.5rem 3rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #0029A1;
}

.fa-bars {
  color: #000345;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    top: 110px;
    left: -100%;
    opacity: 1;
    border-top: 3px solid #0029A1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000345;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-figure > figcaption{
  max-width: 35%;
  align-self: flex-start;
  padding-left: 4rem;
  z-index: 100;
}

.hero-figure > figcaption > p {
  color: #fff;
  font-size: 96px;
  font-weight: 900;
  text-align: left;
}

.hero-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.hero-figure {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  padding-bottom: 13%;
  overflow: hidden;
}


.hero-bottom-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hero-bottom-wrapper {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #0029A1;
  align-items: center;
  border-radius: 12px;

}

.hero-bottom-items {
  background-color: #0029A1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.hero-bottom-items > h1 {
  color: #fff;
  font-size: 40px;
  display: inline;
  margin: 1rem 0 1rem 0;
}

.hero-bottom-items > i {
  color: #fff;
  margin-top: -5px;
  margin-right: 1rem;
  font-size: 3em;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .hero-figure > figcaption > p {
    font-size: 72px;
    max-width: 100%;
  }

  .hero-bottom-wrapper {
    flex-direction: column;
  }

  .hero-bottom-items {
      padding: 0.5rem;
      grid-gap: 20px;
      gap: 20px;
      
  }

  .hero-bottom-items > h1 {
    font-size: 24px;
  }
}
footer {
  background: #0029A1;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 222px
}

.footer-container {
    background: #0029A1;
    width: 100%;
    padding: 30px 0px;
    max-height: 200px;
    
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-evenly;
  }

.contacts {
  margin-right: 20%;
}

.socials {
  margin-left: 20%;
}

.socials h3 {
  text-align: left;
}
  
  footer h3 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  footer p {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
  }
  
  footer ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-bottom: 20px;
    grid-gap: 30px;
    gap: 30px;
    display: flex;
  }
  
  footer ul li i {
    color: #fff;
    font-size: 3em;
    margin-bottom: 10px;
    display: inline;
  }

  footer ul li i:hover {
    color: black;
  }

  @media screen and (max-width: 480px) {

    .footer-container {
      justify-content: center;
    }

    .contacts {
      margin-right: 0%;
    
    }
    
    .socials {
      margin-left: 3%;
    }

    .contacts > h3 {
      font-size: 12px;
    }
    .socials > h3 {
      font-size: 12px;
    }

    footer ul li i {
      color: #fff;
      font-size: 2em;
      margin-bottom: 10px;
      display: inline;
    }
  }

@media screen and (max-width: 960px) and (min-width: 480px) {

  .contacts {
    margin-right: 5%;
  
  }
  
  .socials {
    margin-left: 5%;
  }

  .contacts > h3 {
    font-size: 20px;
  }
  .socials > h3 {
    font-size: 20px;
  }

  footer ul li i {
    color: #fff;
    font-size: 2em;
    margin-bottom: 10px;
    display: inline;
  }
}
  
:root {
    --button-color: #D9D9D9;
    --text-color: #406CDC;
}

.washtype-container {
  background: #0029A1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  object-fit: contain;
}

.washtype-wrapper {
  max-width: 70%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.washtype-item {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.washtype-subtitle {
  align-self: center;
  display: inline-block;
  text-align: center;
  margin: 2rem;
  padding: 0.5rem 4rem;
  background-color: #D9D9D9;
  background-color: var(--button-color);
  border-radius: 12px;
  color: #406CDC;
  color: var(--text-color);
}

.washtype-desc {
  color: #fff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;

  /* or 167% */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0 1 rem 0;
  margin-bottom: 2rem;
}

.washtype-desc > ul > li {
  margin-left: 1em;
}


@media screen and (max-width: 960px) {
  .washtype-wrapper {
    flex-direction: column;
    grid-gap: 0px;
    gap: 0px;
  }

  .washtype-subtitle {
    margin: 1rem;
    font-size: 20px;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .washtype-item {
    max-width: 100%;
  }

  .wasthype-desc > p{
    margin-bottom: 1rem;
  }

  .washtype-desc {
    font-size: 20px;
  }

  .washtype-desc > ul > li {
    margin-left: 1em;
    font-size: 20px;
    margin: 2px;
  }
  
}


#cards {
  scroll-margin-top: 110px;
}

.cards {
    padding: 2rem;
    background: #fff;
  }
  h1, h3 {
    text-align: center;
  }

  h1 {
    color: #0029A1;
  }
  
  .cards__container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 1em auto;
  }
  
  /* .cards__wrapper {
    position: relative;
    margin: 50px 20px 45px 20px;
  } */
  
  .cards__items {
    display: flex;
    flex-grow: 1;
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    -webkit-animation-name: fade-img;
            animation-name: fade-img;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
  
/*  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  } */
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #000345;
    font-size: 18px;
    line-height: 24px;
  }
  
  
  @media only screen and (max-width: 960px) {
    .cards__items {
      flex-direction: column;
    }
  }
  
#quote {
    scroll-margin-top: 110px;
}

.quote-container {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quote-wrapper {
    justify-self: center;
    position: relative;
    overflow: hidden;
    padding-top: 80%;
    width: 70%;
    max-width: 70%;
    margin-bottom: 2rem;
}

.airtable-embed {
    background: transparent;
    border: 1px solid #ccc;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    
}

.quote-container > h1 {
    margin-top: 1rem;
    justify-self: flex-start;
}
#about {
    scroll-margin-top: 110px;
}

.about-container {
    background: #0029A1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2rem;
}

.about-container > h1 {
    margin-top: 1rem;
    color: #fff;
}

.about-wrapper {
    display: flex;
    width: 70%;
    max-width: 70%;
    flex-direction: column;
    justify-content: center;
}

.about-wrapper > p {
    text-align: left;
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    margin: 1rem 0 1rem 0;
}

.about-items {
    width: 70%;
    max-width: 70%;
    display: flex;
    align-items: row;

}

@media screen and (max-width: 960px) {
    .about-wrapper {
        width: 90%;
        max-width: 90%;
    }
    .about-container > h1 {
        font-size: 20px;
    }

    .about-wrapper > p {
        margin: 0.5rem 0 0.5rem 0;
        font-size: 20px;
    }
}
