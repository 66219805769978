#quote {
    scroll-margin-top: 110px;
}

.quote-container {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quote-wrapper {
    justify-self: center;
    position: relative;
    overflow: hidden;
    padding-top: 80%;
    width: 70%;
    max-width: 70%;
    margin-bottom: 2rem;
}

.airtable-embed {
    background: transparent;
    border: 1px solid #ccc;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    
}

.quote-container > h1 {
    margin-top: 1rem;
    justify-self: flex-start;
}